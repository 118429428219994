import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Distribution from '../views/homepage/Distribution.vue'
import Decision from '../views/homepage/Decision.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Login',
    component: Login,
}, {
    path: '/home',
    name: 'home',
    component: Home,
    children: [{
        path: 'distribution',
        name: 'Distribution',
        component: Distribution,
        meta: { isAuth: true },
    }, {
        path: 'decision',
        name: 'Decision',
        component: Decision,
        meta: { isAuth: true },
    }],
    meta: { isAuth: true },
}, ]

const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
    //全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
    //如果路由需要跳转
    if (to.meta.isAuth) {
        //判断 如果school本地存储是qinghuadaxue的时候，可以进去
        if (window.sessionStorage.getItem('login') == 'true') {
            next() //放行
        } else {
            alert('请先登录后再查看！', )
            window.location.href = ('/')
        }
    } else {
        // 否则，放行
        next()
    }
})
export default router