//引入上边封装的axios文件
import request from "@/request";
// import QS from "qs";


//获取用户信息  post请求
// 登录
export const getUserInfo = data => {
    return request({
        url: "/api/distributor/login",
        method: "POST",
        headers: {
            "content-type": "application/json;charset=UTF-8"
        },
        data
    });
};

//  分销数据
export const live_data_zx = data => {
    return request({
        url: "/api/distributor/live_data_zx",
        method: "POST",
        headers: {
            "content-type": "application/json;charset=UTF-8"
        },
        data
    });
};
//  分销数据
export const live_data_tj = data => {
    return request({
        url: "/api/distributor/live_data_tj",
        method: "POST",
        headers: {
            "content-type": "application/json;charset=UTF-8"
        },
        data
    });
};

//  扫码记录
export const user_select = data => {
    return request({
        url: "/api/distributor/user_select",
        method: "POST",
        headers: {
            "content-type": "application/json;charset=UTF-8"
        },
        data
    });
};
//  用户交易查询
export const user_order_select = data => {
    return request({
        url: "/api/distributor/user_order_select",
        method: "POST",
        headers: {
            "content-type": "application/json;charset=UTF-8"
        },
        data
    });
};
//  用户交易查询
export const user_admin = data => {
    return request({
        url: "/api/distributor/user_admin",
        method: "POST",
        headers: {
            "content-type": "application/json;charset=UTF-8"
        },
        data
    });
};
//  用户交易查询
export const order_admin = data => {
    return request({
        url: "/api/distributor/order_admin",
        method: "POST",
        headers: {
            "content-type": "application/json;charset=UTF-8"
        },
        data
    });
};
//  下载
export const download_order = data => {
    return request({
        url: "/api/distributor/download_order",
        method: "POST",
        // headers: {
        //     "content-type": "application/json;charset=UTF-8"
        // },
        responseType: 'blob',
        data
    });
};

//下载用户上传模板   get请求
// export const downLoadTemplate = params => {
//     return request({
//         url: "/info/template",
//         method: "GET",
//         headers: {
//             "content-type": "application/json;charset=UTF-8"
//         },
//         params
//     });
// };