<template>
  <div>
    <!-- <div class="demo-spin-col" style="position: fixed;top: 0;z-index: 9999;width: 100%;height: 100%;">
      <Spin fix>
        <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
        <div style="font-size: 30px;">加载中</div>
      </Spin>
    </div> -->
    <!-- 今日实时数据 -->
    <div class="topbox">
      <div
        style="
          font-size: 18px;
          margin: 10px 5px 5px 10px;
          display: flex;
          justify-content: space-between;
        "
      >
        今日实时数据
        <div>
          <!-- @click="getlive_data" -->
          <Icon type="md-sync" v-if="!flushed" @click="getlive_data" />
          <Spin v-if="flushed" />
          <!-- <div>
            {{ info1.update_time.split("T")[0]
            }}{{ info1.update_time.split("T")[1].split(".000")[0] }}
          </div> -->
        </div>
      </div>
      <div class="kuai">
        <div>标记人数</div>
        <div v-if="!flushed">
          {{ info1.df[0]["zx_mark_size"] ? info1.df[0]["zx_mark_size"] : 0 }}
        </div>
        <div v-else style="font-size: 14px; color: gray">正在查询中...</div>
      </div>
      <div class="kuai">
        <div>首次标记人数</div>
        <div v-if="!flushed">
          {{
            info1.df[0]["zx_first_mark_size"]
              ? info1.df[0]["zx_first_mark_size"]
              : 0
          }}
        </div>
        <div v-else style="font-size: 14px; color: gray">正在查询中...</div>
      </div>
      <div class="kuai">
        <div>开通基础会员人数</div>
        <div v-if="!flushed">
          {{ info1.df[0]["zx_low_size"] ? info1.df[0]["zx_low_size"] : 0 }}
        </div>
        <div v-else style="font-size: 14px; color: gray">正在查询中...</div>
      </div>
      <div class="kuai">
        <div>开通高级会员</div>
        <div v-if="!flushed">
          {{
            info1.df[0]["zx_center_size"] ? info1.df[0]["zx_center_size"] : 0
          }}
        </div>
        <div v-else style="font-size: 14px; color: gray">正在查询中...</div>
      </div>
      <div class="kuai">
        <div>购买单份报告</div>
        <div v-if="!flushed">
          {{ info1.df[0]["zx_one"] ? info1.df[0]["zx_one"] : 0 }}
        </div>
        <div v-else style="font-size: 14px; color: gray">正在查询中...</div>
      </div>
      <div class="kuai">
        <div>今日分成金额</div>
        <div v-if="!flushed">
          {{ info1.df[0]["zx_day_pay"] ? info1.df[0]["zx_day_pay"] : 0 }}
        </div>
        <div v-else style="font-size: 14px; color: gray">正在查询中...</div>
      </div>
      <div class="kuai">
        <div>本月分成金额</div>
        <div v-if="!flushed">
          {{ info1["zx_month_money"] ? info1["zx_month_money"] : 0 }}
        </div>
        <div v-else style="font-size: 14px; color: gray">正在查询中...</div>
      </div>
      <div class="kuai">
        <div>累计分成金额</div>
        <div v-if="!flushed">
          {{ info1["zx_all_money"] ? info1["zx_all_money"] : 0 }}
        </div>
        <div v-else style="font-size: 14px; color: gray">正在查询中...</div>
      </div>
      <div class="kuai">
        <div>退款金额</div>
        <div v-if="!flushed">
          {{ info1.df[0]["zx_refund"] ? info1.df[0]["zx_refund"] : 0 }}
        </div>
        <div v-else style="font-size: 14px; color: gray">正在查询中...</div>
      </div>
      <div class="kuai">
        <div>今日收益</div>
        <div v-if="!flushed">
          {{ info1.df[0]["zx_shouyi"] ? info1.df[0]["zx_shouyi"] : 0 }}
        </div>
        <div v-else style="font-size: 14px; color: gray">正在查询中...</div>
      </div>
    </div>
    <!-- 数据统计 -->
    <div class="topbox">
      <div style="font-size: 18px; margin: 10px 5px 5px 10px">数据统计</div>
      <!-- 数据指标 -->
      <div style="margin: 15px; display: flex; align-items: center">
        <div style="color: gray; margin-right: 20px">数据指标</div>
        <div style="display: flex">
          <!-- :class="btn==1?'btnclickok':''" -->
          <div
            class="buttype"
            :class="btn == 1 ? 'btnclickok' : ''"
            @click="clickbtn(1)"
          >
            标记人数
          </div>
          <div
            class="buttype"
            :class="btn == 2 ? 'btnclickok' : ''"
            @click="clickbtn(2)"
          >
            开通基础会员人数
          </div>
          <div
            class="buttype"
            :class="btn == 3 ? 'btnclickok' : ''"
            @click="clickbtn(3)"
          >
            开通高级会员
          </div>
          <div
            class="buttype"
            :class="btn == 4 ? 'btnclickok' : ''"
            @click="clickbtn(4)"
          >
            购买单份报告
          </div>
          <div
            class="buttype"
            :class="btn == 5 ? 'btnclickok' : ''"
            @click="clickbtn(5)"
          >
            分成金额
          </div>
          <div
            class="buttype"
            :class="btn == 6 ? 'btnclickok' : ''"
            @click="clickbtn(6)"
          >
            退款金额
          </div>
          <div
            style="
              border: 1px solid #00000048;
              padding: 5px 20px;
              cursor: pointer;
            "
            class="buttype"
            :class="btn == 7 ? 'btnclickok' : ''"
            @click="clickbtn(7)"
          >
            收益
          </div>
          <!-- <div style="border: 1px solid #00000048;padding: 5px 20px;cursor: pointer;"
            @click="clickbtn(4)"
            :class="btn == 4 ? 'btnclickok' : ''"
          >
            会员兑换数
          </div> -->
        </div>
      </div>
      <!-- 数据时间 -->
      <div style="margin: 15px; display: flex; align-items: center">
        <div style="color: gray; margin-right: 20px">数据时间</div>
        <div style="display: flex">
          <DatePicker
            @on-change="updatatime"
            type="daterange"
            format="yyyy-MM-dd"
            placeholder="请选择需要查询的起始日期"
            style="width: 300px"
            @on-ok="search2"
            v-model="time"
          />
          <div>
            <Button type="primary" ghost @click="jinyizhou(1)">近一周</Button>
            <Button type="primary" ghost @click="jinyiyue(1)">近一月</Button>
            <span style="margin-left: 15px"
              >合计：{{
                btn == 2
                  ? count2
                  : btn == 3
                  ? count3
                  : btn == 4
                  ? count4
                  : btn == 5
                  ? count5.toFixed(1)
                  : btn == 6
                  ? count6
                  : btn == 7
                  ? count7
                  : count1
              }}</span
            >
          </div>
        </div>
      </div>
      <!-- echarts -->
      <div v-if="btn == 1">
        <div style="width: 100%">
          <div id="main1" class="main_container" v-if="data1show == 1"></div>
        </div>
        <div
          style="text-align: center; height: 300px; line-height: 300px"
          v-show="data1show == 2"
        >
          <Spin size="large" style="display: inline-block" />
        </div>
        <div
          style="
            width: 100%;
            height: 300px;
            line-height: 300px;
            text-align: center;
          "
          v-show="data1show == 3"
        >
          暂无数据，请换个查询条件再试试吧~
        </div>
      </div>
      <div v-if="btn == 2">
        <!-- <div id="main2" class="main_container"  v-if="this.xdata2.length!=0"></div> -->
        <div style="width: 100%">
          <div id="main2" class="main_container" v-if="data2show == 1"></div>
        </div>
        <div
          style="text-align: center; height: 300px; line-height: 300px"
          v-show="data2show == 2"
        >
          <Spin size="large" style="display: inline-block" />
        </div>
        <div
          style="
            width: 100%;
            height: 300px;
            line-height: 300px;
            text-align: center;
          "
          v-show="data2show == 3"
        >
          暂无数据，请换个查询条件再试试吧~
        </div>
      </div>
      <div v-if="btn == 3">
        <!-- <div id="main3" class="main_container" v-if="this.xdata3.length!=0"></div> -->
        <div style="width: 100%">
          <div id="main3" class="main_container" v-if="data3show == 1"></div>
        </div>
        <div
          style="text-align: center; height: 300px; line-height: 300px"
          v-show="data3show == 2"
        >
          <Spin size="large" style="display: inline-block" />
        </div>
        <div
          style="
            width: 100%;
            height: 300px;
            line-height: 300px;
            text-align: center;
          "
          v-show="data3show == 3"
        >
          暂无数据，请换个查询条件再试试吧~
        </div>
      </div>
      <div v-if="btn == 4">
        <!-- <div id="main4" class="main_container" v-if="this.xdata4.length!=0"></div> -->
        <div style="width: 100%">
          <div id="main4" class="main_container" v-if="data4show == 1"></div>
        </div>
        <div
          style="text-align: center; height: 300px; line-height: 300px"
          v-show="data4show == 2"
        >
          <Spin size="large" style="display: inline-block" />
        </div>
        <div
          style="
            width: 100%;
            height: 300px;
            line-height: 300px;
            text-align: center;
          "
          v-show="data4show == 3"
        >
          暂无数据，请换个查询条件再试试吧~
        </div>
      </div>
      <div v-if="btn == 5">
        <!-- <div id="main4" class="main_container" v-if="this.xdata4.length!=0"></div> -->
        <div style="width: 100%">
          <div id="main5" class="main_container" v-if="data5show == 1"></div>
        </div>
        <div
          style="text-align: center; height: 300px; line-height: 300px"
          v-show="data5show == 2"
        >
          <Spin size="large" style="display: inline-block" />
        </div>
        <div
          style="
            width: 100%;
            height: 300px;
            line-height: 300px;
            text-align: center;
          "
          v-show="data5show == 3"
        >
          暂无数据，请换个查询条件再试试吧~
        </div>
      </div>
      <div v-if="btn == 6">
        <!-- <div id="main4" class="main_container" v-if="this.xdata4.length!=0"></div> -->
        <div style="width: 100%">
          <div id="main6" class="main_container" v-if="data6show == 1"></div>
        </div>
        <div
          style="text-align: center; height: 300px; line-height: 300px"
          v-show="data6show == 2"
        >
          <Spin size="large" style="display: inline-block" />
        </div>
        <div
          style="
            width: 100%;
            height: 300px;
            line-height: 300px;
            text-align: center;
          "
          v-show="data6show == 3"
        >
          暂无数据，请换个查询条件再试试吧~
        </div>
      </div>
      <div v-if="btn == 7">
        <!-- <div id="main4" class="main_container" v-if="this.xdata4.length!=0"></div> -->
        <div style="width: 100%">
          <div id="main7" class="main_container" v-if="data7show == 1"></div>
        </div>
        <div
          style="text-align: center; height: 300px; line-height: 300px"
          v-show="data7show == 2"
        >
          <Spin size="large" style="display: inline-block" />
        </div>
        <div
          style="
            width: 100%;
            height: 300px;
            line-height: 300px;
            text-align: center;
          "
          v-show="data7show == 3"
        >
          暂无数据，请换个查询条件再试试吧~
        </div>
      </div>
    </div>
    <!-- 用户管理 -->
    <div class="topbox">
      <div style="font-size: 18px; margin: 10px 5px 5px 10px">用户管理</div>
      <!-- 数据时间 -->
      <div style="margin: 15px; display: flex; align-items: center">
        <div style="color: gray; margin-right: 20px">数据时间</div>
        <div style="display: flex">
          <DatePicker
            @on-change="updatatime1"
            type="daterange"
            format="yyyy-MM-dd"
            placeholder="请选择需要查询的起始日期"
            style="width: 300px"
            @on-ok="search4"
            v-model="time1"
          />
          <div>
            <Button type="primary" ghost @click="jinyizhou(2)">近一周</Button>
            <Button type="primary" ghost @click="jinyiyue(2)">近一月</Button>
            <Button type="primary" ghost @click="dangri(2)">当日</Button>
          </div>
        </div>
      </div>

      <!-- Table -->
      <div>
        <div style="width: 95%; margin: auto">
          <Table
            border
            :columns="columns3"
            :loading="loading3"
            :data="data3"
          ></Table>
        </div>
        <div style="text-align: right; margin-right: 100px; margin-top: 20px">
          <Page :total="total3" @on-change="total3chage" show-elevator />
        </div>
      </div>
    </div>
    <!-- 订单管理 -->
    <div class="topbox">
      <div
        style="
          font-size: 18px;
          margin: 10px 5px 5px 10px;
          display: flex;
          justify-content: space-between;
        "
      >
        订单管理<Button
          type="primary"
          style="margin-right: 100px"
          @click="download"
          :loading="downloading"
          ><span v-if="!downloading">下载</span>
          <span v-else>下载中...</span></Button
        >
      </div>
      <!-- 数据时间 -->
      <div style="margin: 15px; display: flex; align-items: center">
        <div style="color: gray; margin-right: 20px">数据时间</div>
        <div style="display: flex">
          <DatePicker
            @on-change="updatatime2"
            type="daterange"
            format="yyyy-MM-dd"
            placeholder="请选择需要查询的起始日期"
            style="width: 300px"
            @on-ok="search5"
            v-model="time2"
          />
          <div>
            <Button type="primary" ghost @click="jinyizhou(3)">近一周</Button>
            <Button type="primary" ghost @click="jinyiyue(3)">近一月</Button>
            <Button type="primary" ghost @click="dangri(3)">当日</Button>
          </div>
        </div>
      </div>

      <!-- Table -->
      <div>
        <div style="width: 95%; margin: auto">
          <Table
            border
            :columns="columns4"
            :loading="loading4"
            :data="data4"
          ></Table>
        </div>
        <div style="text-align: right; margin-right: 100px; margin-top: 20px">
          <Page :total="total4" @on-change="total4chage" show-elevator />
        </div>
      </div>
    </div>
    <!-- 用户查询 -->
    <div class="topbox">
      <div style="font-size: 18px; margin: 10px 5px 5px 10px">用户查询</div>
      <!-- 数据时间 -->
      <div style="margin: 15px; display: flex; align-items: center">
        <div style="color: gray; margin-right: 20px">用户id</div>
        <div style="display: flex">
          <Input v-model="userid" placeholder="请输入用户ID"></Input>
          <Button type="primary" style="margin-left: 10px" @click="search3"
            >查询</Button
          >
        </div>
      </div>
      <!-- 数据指标 -->
      <div style="margin: 15px; display: flex; align-items: center">
        <div style="color: gray; margin-right: 20px">指标筛选</div>
        <div style="display: flex">
          <!-- :class="btn==1?'btnclickok':''" -->
          <div
            class="buttype"
            :class="select == 1 ? 'btnclickok' : ''"
            style="border-radius: 50px; border: 1px solid #00000048"
            @click="clickselect(1)"
          >
            扫码记录查询
          </div>
          <div
            class="buttype"
            :class="select == 2 ? 'btnclickok' : ''"
            style="
              border-radius: 50px;
              border: 1px solid #00000048;
              margin-left: 20px;
            "
            @click="clickselect(2)"
          >
            用户交易查询
          </div>
        </div>
      </div>

      <!-- echarts -->
      <div v-if="select == 1">
        <div style="width: 95%; margin: auto">
          <Table
            border
            :columns="columns1"
            :loading="loading"
            :data="data1"
          ></Table>
        </div>
        <div style="text-align: right; margin-right: 100px; margin-top: 20px">
          <Page :total="total1" @on-change="total1chage" show-elevator />
        </div>
      </div>
      <div v-if="select == 2">
        <div style="width: 95%; margin: auto">
          <Table
            border
            :columns="columns2"
            :loading="loading"
            :data="data2"
          ></Table>
        </div>
        <div style="text-align: right; margin-right: 100px; margin-top: 20px">
          <Page :total="total2" @on-change="total2chage" show-elevator />
        </div>
      </div>
    </div>
    <!-- 扫码记录 -->
    <Modal v-model="saoma" footer-hide title="扫码记录" width="90vw">
      <div style="width: 95%; margin: auto; height: 70vh">
        <Table
          border
          :columns="columns1"
          :loading="loading"
          :data="data1"
        ></Table>
      </div>
      <div style="text-align: right; margin-right: 100px; margin-top: 20px">
        <Page :total="total1" @on-change="total1chage" show-elevator />
      </div>
    </Modal>
    <!-- 交易记录 -->
    <Modal v-model="jiaoyi" footer-hide title="交易记录" width="90vw">
      <div style="width: 95%; margin: auto; height: 70vh">
        <Table
          border
          :columns="columns2"
          :loading="loading"
          :data="data2"
        ></Table>
      </div>
      <div style="text-align: right; margin-right: 100px; margin-top: 20px">
        <Page :total="total2" @on-change="total2chage" show-elevator />
      </div>
    </Modal>
  </div>
</template>

<script>
import { nextTick } from "vue";
// import echarts from 'echarts'
import {
  live_data_tj,
  user_select,
  user_order_select,
  user_admin,
  order_admin,
  download_order,
} from "@/request/api";
export default {
  data() {
    return {
      saoma: false,
      jiaoyi: false,
      loading3: false,
      loading4: false,
      loading: false,
      buttonSize: 0,
      flushed: false,
      btn: 1,
      time: [],
      time2: [],
      starttime: "",
      endtime: "",
      starttime2: "",
      endtime2: "",
      select: 1,
      userid: "",
      columns1: [
        {
          title: "用户ID",
          key: "用户id",
        },
        {
          title: "登录时间",
          key: "登录时间",
        },
        {
          title: "扫码入口",
          key: "扫码入口",
        },
      ],
      data1: [],
      columns2: [
        {
          title: "用户ID",
          key: "用户ID",
        },
        {
          title: "商品名称",
          key: "商品名称",
        },
        {
          title: "价格",
          key: "价格",
        },
        {
          title: "交易时间",
          key: "交易时间",
        },
        {
          title: "交易平台",
          key: "交易平台",
        },
        {
          title: "退款时间",
          key: "refund_time",
        },
        {
          title: "订单状态",
          key: "order_status",
        },
        {
          title: "a",
          key: "a",
        },

        {
          title: "b",
          key: "b",
        },
        {
          title: "c",
          key: "c",
        },
        {
          title: "d",
          key: "d",
        },
        {
          title: "分成金额",
          key: "分成金额",
        },
      ],
      columns3: [
        {
          title: "日期",
          key: "create_time",
        },
        {
          title: "用户ID",
          key: "phone",
        },
        {
          title: "最新登录时间",
          key: "theday",
        },
        {
          title: "扫码记录",
          key: "扫码记录",
          render: (h, params) => {
            return h(
              "Button",
              {
                style: {
                  background: " rgb(0, 162, 255)",
                  color: "#fff",
                },
                on: {
                  click: () => {
                    this.userid = params.row.phone;
                    this.page1 = 1;
                    this.searchuser();
                    this.saoma = true;
                  },
                },
              },
              "扫码记录"
            );
          },
        },
        {
          title: "交易记录",
          key: "交易记录",
          render: (h, params) => {
            return h(
              "Button",
              {
                style: {
                  background: " rgb(0, 162, 255)",
                  color: "#fff",
                },
                on: {
                  click: () => {
                    this.userid = params.row.phone;
                    this.page2 = 1;
                    this.user_order_select();
                    this.jiaoyi = true;
                  },
                },
              },
              "交易记录"
            );
          },
        },
      ],
      columns4: [
        {
          title: "订单时间",
          key: "交易时间",
        },
        {
          title: "订单编号",
          key: "order_number",
        },
        {
          title: "交易平台",
          key: "交易平台",
        },
        {
          title: "用户ID",
          key: "用户ID",
        },
        {
          title: "商品名称",
          key: "商品名称",
        },
        {
          title: "订单金额",
          key: "价格",
        },
        {
          title: "交易状态",
          key: "order_status",
        },
        {
          title: "交易平台",
          key: "交易平台",
        },
        {
          title: "a",
          key: "a",
        },
        {
          title: "b",
          key: "b",
        },
        {
          title: "c",
          key: "c",
        },
        {
          title: "d",
          key: "d",
        },
        {
          title: "分成金额",
          key: "分成金额",
        },
      ],
      data4: [],
      data2: [],
      info1: [],
      seriesdata1: [],
      xdata1: [],
      seriesdata2: [],
      xdata2: [],
      seriesdata3: [],
      xdata3: [],
      seriesdata4: [],
      xdata4: [],
      seriesdata5: [],
      xdata5: [],
      seriesdata6: [],
      xdata6: [],
      seriesdata7: [],
      xdata7: [],
      data1show: 2,
      data2show: 2,
      data3show: 2,
      data4show: 2,
      data5show: 2,
      data6show: 2,
      data7show: 2,
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      count5: 0,
      count6: 0,
      count7: 0,
      total1: "",
      total2: "",
      total3: "",
      total4: "",
      page1: 1,
      page2: 1,
      page3: 1,
      page4: 1,
      time1: [],
      downloading: false,
    };
  },
  mounted() {
    this.oneCharts();
    this.twoCharts();
    this.threeCharts();
    this.fourCharts();
    this.fiveCharts();
    this.sixCharts();
    this.sevenCharts();
  },
  watch: {
    userid(newvalue, oldvalue) {
      this.page1 = 1;
      this.page2 = 1;
      this.total1 = 0;
      this.data1 = [];
      this.total2 = 0;
      this.data2 = [];
    },
  },
  created() {
    this.getlive_data();
    this.search2();
  },
  methods: {
    // 下载
    download() {
      if (this.data4.length == 0) {
        this.$Message.error("请先输入条件搜索吧~");
        return;
      }
      this.downloading = true;
      let time = new Date();
      let time1 = this.filterTime(time).split(" ")[0] + " " + "00:00:00";
      let data = {
        start_date: this.starttime2
          ? this.starttime2.split(" ")[0] + " 00:00:00"
          : time1.split(" ")[0] + " 00:00:00",
        end_date: this.endtime2
          ? this.endtime2.split(" ")[0] + " 23:59:59"
          : time1,
        type: "择校",
      };
      download_order(data)
        .then((res) => {
          this.downloading = false;
          this.$Message.success({
            content: "正在下载，请稍等···",
            duration: 5,
          });
          console.log("resresres", res);
          // let data=res.data
          let dis = decodeURIComponent(res.headers["content-disposition"]);
          // let url=URL.createObjectURL(data)
          let fileName = dis.split("filename*=UTF-8''")[1];
          console.log("filename", fileName);
          let blob = new Blob([res.data], {
            type: "application/octet-stream,charset=UTF-8",
          });
          let downloadElement = document.createElement("a");
          let href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = `${fileName}`; //下载后的文件名，根据需求定义
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
          // this.$Message.success("导出成功");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    jinyizhou(i) {
      console.log(123123);
      let date1 = new Date(new Date().getTime() - 168 * 60 * 60 * 1000);
      let date2 = new Date().getTime() - 24 * 60 * 60 * 1000;
      console.log("date1date1date1");
      if (i == 1) {
        this.time = [date1, date2];
        this.starttime =
          this.filterTime(this.time[0]).split(" ")[0] + " " + "00:00:00";
        this.endtime =
          this.filterTime(this.time[1]).split(" ")[0] + " " + "23:59:59";

        this.search2();
      } else if (i == 2) {
        this.time1 = [date1, date2];
        this.starttime1 =
          this.filterTime(this.time1[0]).split(" ")[0] + " " + "00:00:00";
        this.endtime1 =
          this.filterTime(this.time1[1]).split(" ")[0] + " " + "23:59:59";
        this.total3 = "";
        this.page3 = 1;
        this.search4();
      } else if (i == 3) {
        this.time2 = [date1, date2];
        this.starttime2 =
          this.filterTime(this.time2[0]).split(" ")[0] + " " + "00:00:00";
        this.endtime2 =
          this.filterTime(this.time2[1]).split(" ")[0] + " " + "23:59:59";
        this.total4 = "";
        this.page4 = 1;
        this.search5();
      }
    },
    jinyiyue(i) {
      let date = new Date();
      date.setDate(1);
      // let dateStart = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + '00:00:00'
      let dateStart = this.getLastMonthTime(new Date()) + " " + "00:00:00";
      let currenttime = new Date().getTime() - 24 * 60 * 60 * 1000;
      console.log("当月时间", dateStart, currenttime);
      if (i == 1) {
        this.time = ["", ""];
        this.time = [dateStart, currenttime];
        this.updatatime(this.time);
      } else if (i == 2) {
        this.time1 = ["", ""];
        this.time1 = [dateStart, currenttime];
        this.total3 = "";
        this.page3 = 1;
        this.updatatime1(this.time1);
      } else if (i == 3) {
        this.time2 = ["", ""];
        this.time2 = [dateStart, currenttime];
        this.total4 = "";
        this.page4 = 1;
        this.updatatime2(this.time2);
      }
    },
    dangri(i) {
      let date = new Date();
      // date.setDate(1);
      // let dateStart = date.getFullYear() + '-' + (date.getMonth()) + '-' + date.getDate() + ' ' + '00:00:00'
      let dateStart =  date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate()  + " " + "00:00:00";
      let currenttime = this.getNowDate()
      console.log("当月时间", dateStart, currenttime);
      if (i == 1) {
        this.time = ["", ""];
        this.time = [dateStart, currenttime];
        this.updatatime(this.time);
      } else if (i == 2) {
        this.time1 = ["", ""];
        this.time1 = [dateStart, currenttime];
        this.total3 = "";
        this.page3 = 1;
        this.updatatime1(this.time1);
      } else if (i == 3) {
        this.time2 = ["", ""];
        this.time2 = [dateStart, currenttime];
        this.total4 = "";
        this.page4 = 1;
        this.updatatime2(this.time2);
      }
    },
    getNowDate() {
				var myDate = new Date;
				var year = myDate.getFullYear(); //获取当前年
				var mon = myDate.getMonth() + 1; //获取当前月
				var date = myDate.getDate(); //获取当前日
				var hours = myDate.getHours(); //获取当前小时
				var minutes = myDate.getMinutes(); //获取当前分钟
				var seconds = myDate.getSeconds(); //获取当前秒
				var now = year + "-" + mon + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
				return now;
			},
    // 第二块数据时间
    updatatime(item) {
      // console.log('timetimechange',item);
      if (item[0] != "") {
        // console.log(this.starttime, this.endtime,item);
        this.starttime = this.filterTime(item[0]);
        this.endtime = this.filterTime(item[1]);
        this.search2();
      } else {
        this.starttime = "";
        this.endtime = "";
        this.search2();
      }
    },
    // 用户管理数据时间
    updatatime1(item) {
      this.total3 = "";
      this.page3 = 1;
      console.log("timetimechange", item);
      if (item[0] != "") {
        this.starttime1 = this.filterTime(item[0]);
        this.endtime1 = this.filterTime(item[1]);
        // console.log(this.starttime, this.endtime,item);
        this.search4();
      } else {
        this.starttime1 = "";
        this.endtime1 = "";
        this.search4();
      }
    },
    // 订单管理数据时间
    updatatime2(item) {
      this.total4 = "";
      this.page4 = 1;
      console.log("timetimechange", item);
      if (item[0] != "") {
        this.starttime2 = this.filterTime(item[0]);
        this.endtime2 = this.filterTime(item[1]);
        // console.log(this.starttime, this.endtime,item);
        this.search5();
      } else {
        this.starttime2 = "";
        this.endtime2 = "";
        this.search5();
      }
    },
    total4chage(i) {
      console.log(i);
      this.page4 = i;
      this.search5();
    },
    total3chage(i) {
      console.log(i);
      this.page3 = i;
      this.search4();
    },
    search5() {
      this.loading4 = true;
      console.log("触发了");
      let time = new Date();
      let time1 = this.filterTime(time).split(" ")[0] + " " + "00:00:00";
      let data = {
        start_date: this.starttime2
          ? this.starttime2.split(" ")[0] + " 00:00:00"
          : time1.split(" ")[0] + " 00:00:00",
        end_date: this.endtime2
          ? this.endtime2.split(" ")[0] + " 23:59:59"
          : time1,
        page: this.page4.toString(),
        type: "择校",
      };
      order_admin(data)
        .then((res) => {
          this.loading4 = false;
          if (res.data.code == 200) {
            console.log(res);
            this.data4 = [];
            this.data4 = res.data.data["扫码记录"];

            if (res.data.data.size!='') {
              this.total4 = res.data.data.size;
            }
          }
        })
        .catch((err) => {
          this.loading4 = false;
          console.log(err);
        });
    },
    search4() {
      this.loading3 = true;
      console.log("触发了",this.starttime1,this.endtime1);
      let time = new Date();
      let time1 = this.filterTime(time).split(" ")[0] + " " + "00:00:00";
      let data = {
        start_date: this.starttime1
          ? this.starttime1.split(" ")[0] + " 00:00:00"
          : time1.split(" ")[0] + " 00:00:00",
        end_date: this.endtime1
          ? this.endtime1.split(" ")[0] + " 23:59:59"
          : time1,
        page: this.page3.toString(),
      };
      user_admin(data)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res);
            this.data3 = [];
            this.data3 = res.data.data.df;
            this.loading3 = false;
            if (res.data.data.size!='') {
              this.total3 = res.data.data.size;
            }
          }
        })
        .catch((err) => {
          this.loading3 = false;
          console.log(err);
        });
    },
    // 第一块儿查询
    getlive_data() {
      this.flushed = true;
      let data = {
        start_date:
          this.filterTime(new Date()).split(" ")[0] + " " + "00:00:00",
      };
      live_data_tj(data)
        .then((res) => {
          if (res.data.code == 200) {
            console.log("加载完成");
            this.info1 = [];
            this.info1 = res.data.data;
          }
          this.flushed = false;
        })
        .catch((err) => {
          this.flushed = false;
          // console.log(err);
        });
    },
    search3() {
      this.searchuser();
      this.user_order_select();
    },
    // 点击第三块搜索扫码记录查询
    searchuser() {
      if (!this.userid) {
        this.$Message.warning("请先输入用户ID");
        return;
      }
      this.loading = true;
      let data = {
        user_id: this.userid,
        type: "择校",
        page: this.page1.toString(),
      };
      user_select(data)
        .then((res) => {
          if (res.data.code == 200) {
            console.log("resresres", res);
            this.data1 = [];
            if (res.data.data.size!='') {
              this.total1 = res.data.data.size;
            }

            this.data1 = res.data.data["扫码记录"];
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          // console.log(err);
        });
    },
    total1chage(i) {
      console.log(i);
      this.page1 = i;
      this.searchuser();
    },
    total2chage(i) {
      console.log(i);
      if(i==1){
        this.total2 = ''
      }
      this.page2 = i;
      this.user_order_select();
    },
    // 点击第三块搜索用户交易查询
    user_order_select() {
      if (!this.userid) {
        return;
      }
      let data = {
        user_id: this.userid,
        type: "择校",
        page: this.page2.toString(),
      };
      user_order_select(data)
        .then((res) => {
          if (res.data.code == 200) {
            this.data2 = [];
            if (res.data.data.size!='') {
              this.total2 = res.data.data.size;
            }
            console.log("resresres", res);
            this.data2 = res.data.data["扫码记录"];
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 点击第三块选择模块
    clickselect(i) {
      if (!this.userid) {
        this.$Message.warning("请先输入用户ID再查询");
        return;
      }
      this.select = i;
    },
    // 第二块儿查询
    search2() {
      console.log("time", this.starttime, this.endtime);
      let time = new Date();
      let time1 = this.filterTime(time).split(" ")[0] + " " + "00:00:00";
      let data = {
        start_date: this.starttime
          ? this.starttime.split(" ")[0] + " 00:00:00"
          : time1.split(" ")[0] + " 00:00:00",
        end_date: this.endtime
          ? this.endtime.split(" ")[0] + " 23:59:59"
          : time1,
      };
      this.data1show = 2;
      this.data2show = 2;
      this.data3show = 2;
      this.data4show = 2;
      this.data5show = 2;
      this.data6show = 2;
      this.data7show = 2;
      // console.log('data',data);
      live_data_tj(data)
        .then((res) => {
          if (res.data.code == 200) {
            console.log("第二块echarts", res.data.data["标记人数"]);
            this.seriesdata1 = [];
            this.xdata1 = [];
            this.seriesdata2 = [];
            this.xdata2 = [];
            this.seriesdata3 = [];
            this.xdata3 = [];
            this.seriesdata4 = [];
            this.xdata4 = [];
            this.seriesdata5 = [];
            this.xdata5 = [];
            this.seriesdata6 = [];
            this.xdata6 = [];
            this.seriesdata7 = [];
            this.xdata7 = [];
            this.count1 = 0;
            this.count2 = 0;
            this.count3 = 0;
            this.count4 = 0;
            this.count5 = 0;
            this.count6 = 0;
            this.count7 = 0;
            console.log("res", res);
            for (let i = 0; i < res.data.data.length; i++) {
              let item = res.data.data[i];
              console.log("item", item);
              this.seriesdata1.push(item["time"]);
              this.xdata1.push(item["zx_mark_size"]);
              this.count1 += parseInt(item["zx_mark_size"]);

              this.seriesdata2.push(item["time"]);
              this.xdata2.push(item["zx_low_size"]);
              this.count2 += parseInt(item["zx_low_size"]);

              this.seriesdata3.push(item["time"]);
              this.xdata3.push(item["zx_center_size"]);
              this.count3 += parseInt(item["zx_center_size"]);

              this.seriesdata4.push(item["time"]);
              this.xdata4.push(item["zx_one"]);
              this.count4 += parseInt(item["zx_one"]);

              this.seriesdata5.push(item["time"]);
              this.xdata5.push(item["zx_day_pay"]);
              this.count5 += parseFloat(item["zx_day_pay"]);

              this.seriesdata6.push(item["time"]);
              this.xdata6.push(item["zx_refund"]);
              this.count6 += parseFloat(item["zx_refund"]);

              this.seriesdata7.push(item["time"]);
              this.xdata7.push(item["zx_shouyi"]);
              this.count7 += parseFloat(item["zx_shouyi"]);
            }
            // for (let i = 0; i < res.data.df.length; i++) {
            //   let item = res.data.df[i];
            //   this.seriesdata2.push(item["time"]);
            //   this.xdata2.push(item["zx_low_size"]);
            //   this.count2 += parseInt(item["zx_low_size"]);
            // }
            // for (let i = 0; i < res.data.df["开通高级会员"].length; i++) {
            //   let item = res.data.df["开通高级会员"][i];
            //   this.seriesdata3.push(item["time"]);
            //   this.xdata3.push(item["开通人数"]);
            //   this.count3 += parseInt(item["开通人数"]);
            // }
            // for (let i = 0; i < res.data.df["购买单份报告"].length; i++) {
            //   let item = res.data.df["购买单份报告"][i];
            //   this.seriesdata4.push(item["time"]);
            //   this.xdata4.push(item["开通人数"]);
            //   this.count4 += parseInt(item["开通人数"]);
            // }
            // for (let i = 0; i < res.data.df["交易金额"].length; i++) {
            //   let item = res.data.df["交易金额"][i];
            //   this.seriesdata5.push(item["time"]);
            //   this.xdata5.push(item["交易金额"]);
            //   this.count5 += parseFloat(item["交易金额"]);
            // }
            // for (let i = 0; i < res.data.df["开通AI会员"].length; i++) {
            //   let item = res.data.df["开通AI会员"][i];
            //   this.seriesdata6.push(item["time"]);
            //   this.xdata6.push(item["开通人数"]);
            //   this.count6 += parseInt(item["开通人数"]);
            // }
            if (this.xdata1.length != 0) {
              this.data1show = 1;
            } else {
              this.data1show = 3;
            }
            if (this.xdata2.length != 0) {
              this.data2show = 1;
            } else {
              this.data2show = 3;
            }
            if (this.xdata3.length != 0) {
              this.data3show = 1;
            } else {
              this.data3show = 3;
            }
            if (this.xdata4.length != 0) {
              this.data4show = 1;
            } else {
              this.data4show = 3;
            }
            if (this.xdata5.length != 0) {
              this.data5show = 1;
            } else {
              this.data5show = 3;
            }
            if (this.xdata6.length != 0) {
              this.data6show = 1;
            } else {
              this.data6show = 3;
            }
            if (this.xdata7.length != 0) {
              this.data7show = 1;
            } else {
              this.data7show = 3;
            }
            setTimeout(() => {
              this.showecharts();
            }, 100);
          }
        })
        .catch((err) => {
          // console.log(err);
        });

      // console.log("第二块查询", this.btn);
    },
    showecharts() {
      if (this.btn == 1) {
        nextTick(() => {
          this.oneCharts();
        });
      } else if (this.btn == 2) {
        nextTick(() => {
          this.twoCharts();
        });
      } else if (this.btn == 3) {
        nextTick(() => {
          this.threeCharts();
        });
      } else if (this.btn == 4) {
        nextTick(() => {
          this.fourCharts();
        });
      } else if (this.btn == 5) {
        nextTick(() => {
          this.fiveCharts();
        });
      } else if (this.btn == 6) {
        nextTick(() => {
          this.sixCharts();
        });
      } else if (this.btn == 7) {
        nextTick(() => {
          this.sevenCharts();
        });
      }
    },
    // 点击第二块儿中的选择模块
    clickbtn(i) {
      // this.search2()
      this.btn = i;
      // console.log(i);
      if (i == 1) {
        nextTick(() => {
          this.oneCharts();
        });
      } else if (i == 2) {
        nextTick(() => {
          this.twoCharts();
        });
      } else if (i == 3) {
        nextTick(() => {
          this.threeCharts();
        });
      } else if (i == 4) {
        nextTick(() => {
          this.fourCharts();
        });
      } else if (i == 5) {
        nextTick(() => {
          this.fiveCharts();
        });
      } else if (i == 6) {
        nextTick(() => {
          this.sixCharts();
        });
      } else if (i == 7) {
        nextTick(() => {
          this.sevenCharts();
        });
      }
    },
    // 第一个echarts
    oneCharts() {
      // 初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main1"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.seriesdata1,
        },
        yAxis: {
          type: "value",
        },
        // series: [
        //   {
        //     data: this.xdata1,
        //     type: "line",
        //   },
        // ],

        series: [
          {
            data: this.xdata1,
            type: "line",
            symbolSize: 8,
            symbol: "circle",
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: true,
                  formatter: (params) => {
                    return params.value;
                  },
                },
                borderColor: "blue", // 拐点边框颜色
                lineStyle: {
                  width: 5, // 设置线宽
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
      });
    },
    // 第二个echarts
    twoCharts() {
      // 初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main2"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.seriesdata2,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.xdata2,
            type: "line",
            symbolSize: 8,
            symbol: "circle",
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: true,
                  formatter: (params) => {
                    return params.value;
                  },
                },
                borderColor: "blue", // 拐点边框颜色
                lineStyle: {
                  width: 5, // 设置线宽
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
      });
    },
    // 第三个echarts
    threeCharts() {
      // 初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main3"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   label: {
          //     backgroundColor: "#6a7985",
          //   },
          // },
        },
        xAxis: {
          type: "category",
          data: this.seriesdata3,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.xdata3,
            type: "line",
            symbolSize: 8,
            symbol: "circle",
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: true,
                  formatter: (params) => {
                    return params.value;
                  },
                },
                borderColor: "blue", // 拐点边框颜色
                lineStyle: {
                  width: 5, // 设置线宽
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
      });
    },
    // 第四个echarts
    fourCharts() {
      // 初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main4"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.seriesdata4,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.xdata4,
            type: "line",
            symbolSize: 8,
            symbol: "circle",
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: true,
                  formatter: (params) => {
                    return params.value;
                  },
                },
                borderColor: "blue", // 拐点边框颜色
                lineStyle: {
                  width: 5, // 设置线宽
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
      });
    },
    // 第五个
    fiveCharts() {
      // 初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main5"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.seriesdata5,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.xdata5,
            type: "line",
            symbolSize: 8,
            symbol: "circle",
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: true,
                  formatter: (params) => {
                    return params.value;
                  },
                },
                borderColor: "blue", // 拐点边框颜色
                lineStyle: {
                  width: 5, // 设置线宽
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
      });
    },
    // 第六个
    sixCharts() {
      // 初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main6"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.seriesdata6,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.xdata6,
            type: "line",
            symbolSize: 8,
            symbol: "circle",
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: true,
                  formatter: (params) => {
                    return params.value;
                  },
                },
                borderColor: "blue", // 拐点边框颜色
                lineStyle: {
                  width: 5, // 设置线宽
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
      });
    },
    sevenCharts() {
      // 初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main7"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.seriesdata7,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.xdata7,
            type: "line",
            symbolSize: 8,
            symbol: "circle",
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: true,
                  formatter: (params) => {
                    return params.value;
                  },
                },
                borderColor: "blue", // 拐点边框颜色
                lineStyle: {
                  width: 5, // 设置线宽
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
      });
    },
    // 获取上一个月时间,返回yyyy-MM-dd字符串
    getLastMonthTime(date) {
      //  1    2    3    4    5    6    7    8    9   10    11   12月
      var daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      var strYear = date.getFullYear();
      var strDay = date.getDate();
      var strMonth = date.getMonth() + 1;
      //一、解决闰年平年的二月份天数   //平年28天、闰年29天//能被4整除且不能被100整除的为闰年,或能被100整除且能被400整除
      if ((strYear % 4 === 0 && strYear % 100 !== 0) || strYear % 400 === 0) {
        daysInMonth[2] = 29;
      }
      if (strMonth - 1 === 0) {
        //二、解决跨年问题
        strYear -= 1;
        strMonth = 12;
      } else {
        strMonth -= 1;
      }
      //  strDay = daysInMonth[strMonth] >= strDay ? strDay : daysInMonth[strMonth];
      strDay = Math.min(strDay, daysInMonth[strMonth]); //三、前一个月日期不一定和今天同一号，例如3.31的前一个月日期是2.28；9.30前一个月日期是8.30

      if (strMonth < 10) {
        //给个位数的月、日补零
        strMonth = "0" + strMonth;
      }
      if (strDay < 10) {
        strDay = "0" + strDay;
      }
      var datastr = strYear + "-" + strMonth + "-" + strDay;
      return datastr;
    },
    // 时间转化格式
    filterTime(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + s;
    },
  },
};
</script>

<style scoped>
.main_container {
  width: 98%;
  height: 300px;
  margin: auto;
  overflow: hidden;
}

.buttype {
  border-bottom: 1px solid #00000048;
  border-top: 1px solid #00000048;
  border-left: 1px solid #00000048;
  padding: 5px 20px;
  cursor: pointer;
}

.btnclickok {
  background: #02a7f0;
  color: #fff;
}

.topbox {
  border: 2px solid #00000011;
  box-shadow: 2px 2px 5px #0000002c;
  width: 98%;
  margin: 5px auto 0 auto;
  border-radius: 10px;
  padding-bottom: 30px;
}

.kuai {
  text-align: center;
  display: inline-block;
  width: 20%;
  margin-top: 30px;
}

.kuai > div:nth-child(1) {
  font-size: 16px;
}

.kuai > div:nth-child(2) {
  font-size: 20px;
  font-weight: 600;
}

.demo-spin-col .circular {
  width: 25px;
  height: 25px;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
</style>
