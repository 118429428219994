import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import iView from 'iview'; // 导入组件库
import 'iview/dist/styles/iview.css'; // 导入样式
import * as echarts from "echarts"
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
Vue.prototype.$echarts = echarts

Vue.use(iView);
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')