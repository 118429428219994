<template>
  <div class="layout">
      <Layout style="height: 100vh;">
          <Sider ref="side1" hide-trigger collapsible :collapsed-width="78" v-model="isCollapsed">
              <Menu active-name="1-2" theme="dark" width="auto" :class="menuitemClasses" @on-select="gopage">
                  <MenuItem name="1">
                    <div style="display: flex;align-items: center;">
                        <div :class="imgbigorsmall?'bigsize':'smallsize'" style="border-radius: 50%;overflow: hidden;">
                            <img src="https://ts1.cn.mm.bing.net/th/id/R-C.58a9a49cd4a58ae84a5334a915e8bdfc?rik=YwkaDZTsxMmk8Q&riu=http%3a%2f%2fpic.baike.soso.com%2fugc%2fbaikepic2%2f28361%2fcut-20141129142723-146411252.jpg%2f0&ehk=U7qco1fNYZJXsgbvRPDKmC7CW0hXZ3oozx4yTWT1gxg%3d&risl=&pid=ImgRaw&r=0&sres=1&sresct=1" alt="" style="width: 50px;height: 50px;">
                            <!-- <img src="../assets/login.jpg" alt="" style="width: 50px;height: 50px;"> -->
                        </div>
                      <span style="color: #fff;font-size: 16px;font-weight: 600; margin-left: 5px;width: 100px;overflow: hidden;white-space: normal;text-overflow: ellipsis;" v-if="imgbigorsmall">{{ u_name }}</span>
                    </div>
                  </MenuItem>
                  <MenuItem name="1-2">
                      <Icon type="ios-navigate"></Icon>
                      <span>择校产品分销数据</span>
                  </MenuItem>
                  <MenuItem name="1-1">
                      <Icon type="ios-navigate"></Icon>
                      <span>调剂产品分销数据</span>
                  </MenuItem>
                  
                  <!-- <MenuItem name="1-2">
                      <Icon type="ios-search"></Icon>
                      <span>Option 2</span>
                  </MenuItem>
                  <MenuItem name="1-3">
                      <Icon type="ios-settings"></Icon>
                      <span>Option 3</span>
                  </MenuItem> -->
              </Menu>
          </Sider>
          <Layout>
              <Header :style="{padding: 0}" class="layout-header-bar">
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <Icon @click="collapsedSider" :class="rotateIcon" :style="{margin: '0 20px'}" type="md-menu" size="24"></Icon>
                    <div style="font-size: 18px;padding-right: 30px;" class="quitlogin" @click="quitlogin">退出登录</div>
                </div>
                  
                  
              </Header>
              <Content :style="{margin: '20px', background: '#fff','padding-bottom':'20px'}">
                  <router-view></router-view>
              </Content>
          </Layout>
      </Layout>
  </div>
</template>

<script>
import menu from '@/components/menu.vue';
  export default {
  components: { menu },
        data () {
            return {
                isCollapsed: false,
                imgbigorsmall:true,
                u_name:'',
            }
        },
        computed: {
            rotateIcon () {
                return [
                    'menu-icon',
                    this.isCollapsed ? 'rotate-icon' : ''
                ];
            },
            menuitemClasses () {
                return [
                    'menu-item',
                    this.isCollapsed ? 'collapsed-menu' : ''
                ]
            }
        },
        created(){
            this.u_name=sessionStorage.getItem('u_name')
        },
        methods: {
          gopage(name){
            console.log(123123132132,name);
            if(name=='1-1'){
                this.$router.push('/home/distribution')
            }else if(name=='1-2'){
                this.$router.push('/home/decision')
            }
            
          },
          quitlogin(){
            sessionStorage.setItem('login',false)
            sessionStorage.setItem('token','')
            this.$router.push('/')
          },
            collapsedSider (e) {
              if(this.imgbigorsmall){
                this.imgbigorsmall=false
              }else{
                setTimeout(() => {
                  this.imgbigorsmall=true
                }, 50);
              }
                this.$refs.side1.toggleCollapse();
            }
        }
    }
</script>

<style scoped>
.quitlogin:hover{
    color: rgba(0, 68, 255, 0.719);
}
.quitlogin{
    cursor: pointer;
}
.bigsize{
  width: 50px;height: 50px;
}
.smallsize{
  width: 30px;height: 30px;
}
.layout{
        border: 1px solid #d7dde4;
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
    }
    .layout .ivu-menu{
        z-index: 0
    }
    .layout-header-bar{
        background: #fff;
        box-shadow: 0 1px 1px rgba(0,0,0,.1);
    }
    .layout-logo-left{
        width: 90%;
        height: 30px;
        background: #5b6270;
        border-radius: 3px;
        margin: 15px auto;
    }
    .menu-icon{
        transition: all .3s;
    }
    .rotate-icon{
        transform: rotate(-90deg);
    }
    .menu-item span{
        display: inline-block;
        overflow: hidden;
        width: 130px;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: bottom;
        transition: width .2s ease .2s;
    }
    .menu-item i{
        transform: translateX(0px);
        transition: font-size .2s ease, transform .2s ease;
        vertical-align: middle;
        font-size: 16px;
    }
    .collapsed-menu span{
        width: 0px;
        transition: width .2s ease;
    }
    .collapsed-menu i{
        transform: translateX(5px);
        transition: font-size .2s ease .2s, transform .2s ease .2s;
        vertical-align: middle;
        font-size: 22px;
    }
</style>