/**
 * 请求封装
 */
import axios from 'axios';
// import store from '../store';
import { Message } from 'iview'

const service = axios.create({
    // baseURL: '/txzc',
    baseURL: "/txzc",
    withCredentials: true, // 当跨域请求时发送cookie
    timeout: 1500000 // 请求超时
});

//添加请求拦截器   请求接口统一添加token
service.interceptors.request.use(
    config => {
        // sessionStorage.setItem('login', false)
        config.headers.token = sessionStorage.getItem('token') || ''; //请求添加token
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        //如果接口返回token，替换本地旧token

        if (response.headers.token) {
            window.sessionStorage.setItem("token", response.headers.token);
        }
        console.log('response', response);
        //自定义设置后台返回code对应的响应方式
        // 未登录或登录超时
        if (response.data.code == 601) {
            window.sessionStorage.setItem("token", "");
            window.sessionStorage.setItem("login", false);
            window.location.href = ('/')
                // this.$router.push('/sys/login/login')
            return Promise.reject(new Error('登录超时'));
        } else if (response.data.code != 200 && response.status != 200) {
            Message.warning(response.data.msg);
        } else { //接口正常，返回数据
            return response;
        }
    },
    error => {
        if (error.message) {
            // this.$massage.error('服务器开小差了，请稍后再试!')
            //错误响应
            alert('服务器开小差了，请稍后再试!')
        }
        return Promise.reject(error);
    }
);
//暴露出封装过的服务
export default service;