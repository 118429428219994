<template>
  <div style="border: 0; padding: 0">
    <div style="height: 100vh;display: flex;align-items: center;">
      <div style="width: 55%;">
        <img
          src="../assets/login.jpg"
          alt=""
          style="
            width: 100%;
            background-repeat: no-repeat;
            background-position: center center;
          "
        />
      </div>
      <div style="width: 40%;">
        <div>
          <div class="right">
            <div class="dl">登录</div>
            <div class="srsjh">输入账号</div>
            <div>
              <Form
                @submit.native.prevent
                :model="userForm"
                status-icon
                :rules="rules"
                class="login-form"
                ref="userForm"
                style="width: 80%"
              >
                <FormItem prop="account">
                  <Input
                    v-model="userForm.account"
                    placeholder="请输入账号"
                    class="input"
                  ></Input>
                </FormItem>
              </Form>
            </div>
            <div class="srsjh" style="margin-top: 20px">输入密码</div>
            <Form
              @submit.native.prevent
              :model="userForm"
              status-icon
              :rules="rules"
              class="login-form"
              ref="userForm"
              style="width: 80%"
            >
              <FormItem prop="pwd">
                <Input 
                  v-model="userForm.pwd"
                  :type="pwdType"
                  placeholder="请输入密码"
                  class="input"
                  ><Icon slot="suffix" class="el-icon-view" :type="IconpwdType" @click="showPwd" /></Input>
              </FormItem>
            </Form>
            <div
              style="display: flex; justify-content: space-between; width: 80%"
            >
              <div></div>
            </div>
            <div>
              <Button
                class="btn"
                @click="login"
                @keyup.13="login"
                style="width: 80%"
                >登入</Button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- {{$store.state.usersize}} -->
  </div>
</template>
  
  <script>
  import { getUserInfo  } from '@/request/api'
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length > 18 || value.length < 6) {
        callback(new Error("请输入长度为6到18位的密码"));
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else if (value.length > 24 || value.length < 6) {
        callback(new Error("请输入长度为6到18位的账号"));
      } else {
        callback();
      }
    };
    return {
        IconpwdType:'md-eye-off',
      checked: false,
      pwdType: "password",
      userForm: {
        account: "", // 账号
        pwd: "",
      },
      rules: {
        account: [{ required: true, validator: validatePass1, trigger: "blur" },],
        pwd: [{ required: true, validator: validatePass, trigger: "blur" }],
      },
    };
  },
  mounted() {
      if (
        window.sessionStorage.getItem("login") == 'true'&&window.sessionStorage.getItem("token")!='undefined'&&window.sessionStorage.getItem("token")&&window.sessionStorage.getItem("token")!=null
      ) {
        console.log('触发了',window.sessionStorage.getItem("login"),window.sessionStorage.getItem("token"));
        this.$router.push("/home");
        return;
      }else{
        window.sessionStorage.setItem('login',false)
        window.sessionStorage.setItem('token','')
      }
    window.addEventListener("keydown", this.keyDown); //创建登录事件
  },
  //登录销毁
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    // 全局登录事件
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        this.login();
      }
    },
    login() {
        // this.$router.push("/home");
        console.log('this.userForm.pwd',this.$md5(this.userForm.pwd) );
      if (this.userForm.account.length == 0) {
        this.$Message.error("请填写用户名");
        return;
      }
      if (this.userForm.pwd.length < 6) {
        this.$Message.error("请填写6-16位的正确密码");
        return;
      }
      let data = {
        phone: this.userForm.account,
        password: this.$md5(this.userForm.pwd),
      };
      getUserInfo(data)
        .then((res) => {
          if (res.data.code == 200) {
            console.log('res.data',res.data);
            this.$Message.success("登录成功");
            sessionStorage.setItem("login", true);
            sessionStorage.setItem("u_name", res.data.msg);
            sessionStorage.setItem("token", res.data.data);
            this.$router.push("/home");
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showPwd() {
        this.pwdType === "password"
        ? (this.IconpwdType =  "md-eye")
        : (this.IconpwdType ="md-eye-off");
      this.pwdType === "password"
        ? (this.pwdType = "")
        : (this.pwdType = "password");
      let e = document.getElementsByClassName("el-icon-view")[0];
      this.pwdType == ""
        ? e.setAttribute("style", "color: #c0c4cc")
        : e.setAttribute("style", "color: #409EFF");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
  <style scoped>
* body html {
  border: 0;
  padding: 0;
}
.right {
  margin-left: 60px;
  /* height: 400px; */
  /* background-color: aqua; */
  width: 100%;
}
.dl {
  font-size: 24px;
}
.srsjh {
  margin-top: 60px;
  font-size: 14px;
  margin-bottom: 10px;
  color: rgb(88, 88, 88);
}
.input {
  width: 100%;
}
.btn {
  background-color: #536dfe;
  width: 100%;
  color: #fff;
  border: 0;
  margin-top: 20px;
}
.btn:hover {
  border: 0;
  box-shadow: 0 5px 15px #536dfe;
}
</style>